import moment from 'moment';

/**
 * 转换图表数据
 * @param resData api返回的数据
 * @param columns 表格的列(code name)
 */
export function timeTransformData(resData: any, columns: Array<{code: string, name: string}>, dateFormat: string, fieldName: string) {
    const dataRows = [];
    const dimension = columns[0].code;
    const dataColumns = [dimension];

    _.forEach(resData, item => {
        for (let index = 1; index < columns.length; index++) {
            dataColumns.push(`${item[fieldName]}${columns[index].name}`);
        }
    });

    _.forEach(resData, item => {
        _.forEach(item.data, fd => {
            let dataItem: any = {};
            dataItem[dimension] = moment(fd[dimension]).format(dateFormat);
            if (Array.isArray(fd.values)) {
                // 数据有可能在values里面，不是map的形式
                _.forEach(fd.values, fdValue => {
                    fd[fdValue.key] = fdValue.value;
                });
            }
            for (let index = 1; index < columns.length; index++) {
                let fdValue = null;
                // 百分比保留4位小数
                if (columns[index].code === 'rate') {
                    try {
                        fdValue = fd[columns[index].code].toFixed(4);
                    } catch (error) {
                        $log.warn(error);
                    }
                } else {
                    fdValue = fd[columns[index].code];
                }
                dataItem[`${item[fieldName]}${columns[index].name}`] = fdValue;
            }
            let findData = _.find(dataRows, dr => dr[dimension] === dataItem[dimension]);
            if (findData) {
                findData = Object.assign(findData, dataItem);
            } else {
                dataRows.push(dataItem);
            }
        });
    });

    return {
        columns: dataColumns,
        rows: dataRows
    };
}

function changeTreeItemFieldName(treeItem: any, originName: string, targetName: string) {
    if (_.isArray(treeItem[originName]) || _.isArray(treeItem[targetName])) {
        // 这里假设源字段和目标字段不能同时有值，否则目标字段会被覆盖
        // 源字段和目标字段有一个是数组
        let replaceData = null;
        if (treeItem[originName] && treeItem[originName].length > 0) {
            replaceData = treeItem[originName];
        } else {
            replaceData = treeItem[targetName];
        }
        treeItem[targetName] = _.map(replaceData, item => {
            return changeTreeItemFieldName(item, originName, targetName);
        });
    } else if (treeItem[originName]) {
        treeItem[targetName] = treeItem[originName];
    }
    // delete treeItem[originName]; // 移除源字段
    return treeItem;
}

/**
 * 改变树形数据的字段名
 * @param treeData
 * @param originName
 * @param targetName
 * @returns
 */
export function changeTreeDataFieldName(treeData: Array<any> | any, originName: string, targetName: string) {
    if (_.isArray(treeData)) {
        return _.map(treeData, item => {
            return changeTreeItemFieldName(item, originName, targetName);
        });
    }
    return changeTreeItemFieldName(treeData, originName, targetName);
}

/**
 * 递归获取树形数据的id
 * @param treeData
 * @param depth
 */
export function getTreeIdByDepth(treeData: Array<any> | any, idName: string, depth: number = 1) {
    const ids: Array<string> = [];
    let currentTree = treeData;
    for (let i = 0; i < depth; i++) {
        if (!currentTree) {
            break;
        }
        if (_.isArray(currentTree)) {
            const nextCurrentTree = [];
            _.forEach(currentTree, item => {
                ids.push(item[idName]);
                if (item.children) {
                    nextCurrentTree.push(...item.children);
                }
            });
            currentTree = nextCurrentTree;
        } else {
            ids.push(currentTree[idName]);
            currentTree = currentTree.children;
        }
    }
    return ids;
}
